import { collection, FirestoreDataConverter } from "firebase/firestore";
import {
  useFirestore,
  useFirestoreCollectionData,
  ObservableStatus
} from "reactfire";
import _ from "lodash";

import { useFirestoreBasePath } from "./useFirestoreBasePath";
import { UfflUser } from "../models/uffl";

// TODO: this should be generic
export const ufflUserConverter: FirestoreDataConverter<UfflUser> = {
  toFirestore: (object) => _.mapValues(object, (value) => value),
  fromFirestore: (snapshot, options) => snapshot.data(options) as UfflUser
};

// TODO: this should be calling a provider
export const useUfflUsers: () => ObservableStatus<UfflUser[]> = () => {
  const firestoreBasePath = useFirestoreBasePath();

  const colRef = collection(
    useFirestore(),
    firestoreBasePath,
    "app",
    "users"
  ).withConverter(ufflUserConverter);

  const result = useFirestoreCollectionData(colRef, { idField: "uid" });

  return result;
};
