import { Lineup, UfflPosition } from "./uffl";
import { LineupPlayer } from "./player";

export enum LineupPickError {
  NotValidated = "not validated",
  NoError = "no error",
  NoPlayer = "no player selected",
  InvalidPlayer = "invalid player selected",
  DuplicatePlayer = "duplicate player selected"
}

export type LineupValidation = Record<UfflPosition, LineupPickError>;

const emptyLineupPlayer: LineupPlayer = { playerId: "", substituted: false };

export const emptyLineup: Lineup = {
  FBK: emptyLineupPlayer,
  HBK: emptyLineupPlayer,
  MD1: emptyLineupPlayer,
  MD2: emptyLineupPlayer,
  SPD: emptyLineupPlayer,
  HFW: emptyLineupPlayer,
  FFW: emptyLineupPlayer,
  RBK: emptyLineupPlayer,
  RMD: emptyLineupPlayer,
  RSP: emptyLineupPlayer,
  RFW: emptyLineupPlayer
};

export const emptyLineupValidation: LineupValidation = {
  FBK: LineupPickError.NotValidated,
  HBK: LineupPickError.NotValidated,
  MD1: LineupPickError.NotValidated,
  MD2: LineupPickError.NotValidated,
  SPD: LineupPickError.NotValidated,
  HFW: LineupPickError.NotValidated,
  FFW: LineupPickError.NotValidated,
  RBK: LineupPickError.NotValidated,
  RMD: LineupPickError.NotValidated,
  RSP: LineupPickError.NotValidated,
  RFW: LineupPickError.NotValidated
};
