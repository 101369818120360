import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData, ObservableStatus } from "reactfire";

import { useCompSeasonId } from "./useCompSeasonId";
import { useFirestoreBasePath } from "./useFirestoreBasePath";
import { UfflUserRound, ufflUserRoundConverter } from "../models/round";

// TODO: this should be calling a provider
export const useUfflUserRound: (
  roundId: string,
  userId: string
) => ObservableStatus<UfflUserRound> = (roundId, userId) => {
  const compSeasonId = useCompSeasonId();
  const firestoreBasePath = useFirestoreBasePath();

  const docRef = doc(
    useFirestore(),
    firestoreBasePath,
    "uffl",
    "compSeasons",
    compSeasonId,
    "rounds",
    roundId,
    "teams",
    userId
  ).withConverter(ufflUserRoundConverter);

  const result = useFirestoreDocData(docRef, {
    idField: "uid"
  });

  return result;
};
