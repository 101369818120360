import { FunctionComponent, useCallback, useMemo } from "react";
import { Stack, Select, MenuItem } from "@mui/material";
import { filter, find } from "lodash";

import { useAppSelector } from "../../redux/hooks";
import { useRoundPlayerStats } from "../../hooks/useRoundPlayerStats";

import { RoundResultsTable } from "./RoundResultsTable";

const showAllRounds = true;

export const RoundResults: FunctionComponent = () => {
  const aflRounds = useAppSelector((state) => state.aflRounds.list);
  const ufflRounds = useAppSelector((state) => state.ufflRounds.list);
  const currentRound = useAppSelector((state) => state.roundStatus.currentRound);

  const rounds = useMemo(
    () =>
      showAllRounds || !currentRound ? aflRounds : filter(aflRounds, (r) => r.providerId <= currentRound.providerId),
    [currentRound, aflRounds]
  );

  const { roundId, setRoundId } = useRoundPlayerStats();

  const onChange = useCallback(
    (e) => {
      setRoundId(e.target.value);
    },
    [setRoundId]
  );

  const selectedUfflRound = useMemo(() => find(ufflRounds, ["providerId", roundId]), [roundId, ufflRounds]);

  const results = useMemo(() => {
    if (!selectedUfflRound) {
      return null;
    }
    return <RoundResultsTable round={selectedUfflRound} />;
  }, [selectedUfflRound]);

  return (
    <Stack>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Select displayEmpty value={roundId} onChange={onChange} size="small">
          <MenuItem disabled value="placeholderRoundId">
            <em>Select a round</em>
          </MenuItem>
          {rounds.map((round) => (
            <MenuItem value={round.providerId} key={round.providerId}>
              {round.name}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      {results}
    </Stack>
  );
};
