import { FunctionComponent } from "react";
import { styled, Box } from "@mui/material";

import { RoundEndingAlert } from "./RoundEndingAlert";

const StatusBarContainer = styled(Box)`
  position: relative;
  bottom: 0;
  top: auto;
  width: 100%;
  height: 100%;
`;

export const StatusBar: FunctionComponent = () => {
  return (
    <StatusBarContainer>
      <RoundEndingAlert />
    </StatusBarContainer>
  );
};
