import { FunctionComponent, useEffect } from "react";
import { collection, doc } from "firebase/firestore";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData
} from "reactfire";

import { useAppDispatch } from "../redux/hooks";
import { useFirestoreBasePath } from "../hooks/useFirestoreBasePath";
import { useCompSeasonId } from "../hooks/useCompSeasonId";
import { aflRoundsActions } from "../slices/aflRoundsSlice";
import { ufflRoundsActions } from "../slices/ufflRoundsSlice";

import { UfflRound } from "../models/round";

export const ReactFireListener: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const compSeasonId = useCompSeasonId();
  const firestoreBasePath = useFirestoreBasePath();

  //
  const aflCompSeason = useFirestoreDocData(
    doc(useFirestore(), firestoreBasePath, "afl", "compSeasons", compSeasonId),
    { idField: "providerId" }
  );
  useEffect(() => {
    dispatch(aflRoundsActions.updateRounds(aflCompSeason.data?.rounds));
  }, [aflCompSeason, dispatch]);

  //
  const ufflRounds = useFirestoreCollectionData(
    collection(
      useFirestore(),
      firestoreBasePath,
      "uffl",
      "compSeasons",
      compSeasonId,
      "rounds"
    ),
    { idField: "providerId" }
  );
  useEffect(() => {
    // TODO: should go to the slice as DocumentData?
    dispatch(ufflRoundsActions.updateRounds(ufflRounds.data as UfflRound[]));
  }, [ufflRounds, dispatch]);

  return null;
};
