import { FunctionComponent } from "react";
import { Box } from "@mui/material";

import { RoundResults } from "./roundResults/RoundResults";

export const Home: FunctionComponent = () => {
  return (
    <Box>
      <RoundResults />
    </Box>
  );
};
