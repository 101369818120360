import { FunctionComponent, useMemo } from "react";
import { Table, TableBody } from "@mui/material";
import _ from "lodash";

import { useAflMatches } from "../../hooks/useAflMatches";
import { UfflRound } from "../../models/round";

import { MatchResultsTableRows } from "./MatchResultsTableRows";

export type RoundResultsTableProps = {
  round: UfflRound;
};
export const RoundResultsTable: FunctionComponent<RoundResultsTableProps> = ({
  round
}) => {
  const aflMatches = useAflMatches();
  const roundMatches = useMemo(
    () =>
      aflMatches.data &&
      _.filter(aflMatches.data, ["round.providerId", round.providerId]),
    [aflMatches, round.providerId]
  );

  return (
    <Table>
      <TableBody>
        {round.fixture?.map((match, index) => (
          <MatchResultsTableRows
            roundId={round.providerId}
            match={match}
            roundMatches={roundMatches}
            key={index}
          />
        ))}
      </TableBody>
    </Table>
  );
};
