import { FunctionComponent, ReactNode } from "react";
import { MemoryRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";

type RouterProps = { children?: ReactNode };
export const Router: FunctionComponent<RouterProps> = ({ children }) => {
  if (typeof window === "undefined") {
    return <StaticRouter location="/">{children}</StaticRouter>;
  }

  return (
    <MemoryRouter initialEntries={["/"]} initialIndex={0}>
      {children}
    </MemoryRouter>
  );
};
