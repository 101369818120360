import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThemeOptions } from "@mui/material";

export interface ThemeState {
  googleFontName: string;
  options: ThemeOptions;
}

const defaultGoogleFontName = "Manrope";
const defaultFontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
const fontFamily = (googleFont: string) => (googleFont ? `"${googleFont}", ${defaultFontFamily}` : defaultFontFamily);

const initialState: ThemeState = {
  googleFontName: defaultGoogleFontName,
  options: {
    palette: {
      mode: "dark",
      primary: {
        main: "#aeea00"
      },
      secondary: {
        main: "#3b00ea"
      }
    },
    typography: {
      fontFamily: fontFamily(defaultGoogleFontName)
    }
  }
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.options = {
        ...state.options,
        palette: {
          ...state.options.palette,
          mode: state.options.palette?.mode === "dark" ? "light" : "dark"
        }
      };
    },
    setGoogleFontName: (state, action: PayloadAction<string>) => {
      state.googleFontName = action.payload;
      state.options = {
        ...state.options,
        typography: {
          ...state.options.typography,
          fontFamily: fontFamily(action.payload)
        }
      };
    }
  }
});

export const themeActions = themeSlice.actions;
export default themeSlice.reducer;
