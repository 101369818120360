import { FunctionComponent, useState, useMemo, useCallback } from "react";
import { TableRow } from "@mui/material";
import { find, intersectionBy } from "lodash";

import { useUfflUserRound } from "../../hooks/useUfflUserRound";
import { useAflClubs } from "../../hooks/useAflClubs";
import { usePlayerDetails } from "../../hooks/usePlayerDetails";
import { useRoundPlayerStats } from "../../hooks/useRoundPlayerStats";
import { AflMatch } from "../../models/afl";
import { UfflStartingPosition } from "../../models/uffl";
import { getUfflTeamResult } from "../../models/scoring";
import { PlayerMetadataBase, getDisplayItem } from "../../models/player";

import { MatchResultsTableRowDetails } from "./MatchResultsTableRowDetails";
import { Cell, HeaderCell } from "./MatchResultsTableStyles";

interface MatchResultsTableRowProps {
  roundId: string;
  userId: string;
  teamName: string;
  roundMatches: AflMatch[];
}

export const MatchResultsTableRow: FunctionComponent<MatchResultsTableRowProps> = ({
  roundId,
  userId,
  teamName,
  roundMatches
}) => {
  const allPlayerDetails = usePlayerDetails();
  const round = useUfflUserRound(roundId, userId);
  const aflClubs = useAflClubs();
  const { playerStats } = useRoundPlayerStats();

  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen((prev) => !prev), []);

  const lineup = round.data?.lineup;

  const clubId = round.data?.club;
  const clubName = useMemo(() => find(aflClubs.data, ["providerId", clubId])?.name ?? "???", [aflClubs, clubId]);

  const players = useMemo(
    () =>
      round.data?.players?.map(
        (p): PlayerMetadataBase => ({
          playerId: p
        })
      ) ?? [],
    [round.data?.players]
  );

  const stats = useMemo(() => intersectionBy(playerStats, players, "playerId"), [players, playerStats]);

  const playerDetails = useMemo(
    () => (allPlayerDetails.data && intersectionBy(allPlayerDetails.data, players, "playerId")) ?? [],
    [players, allPlayerDetails]
  );

  const displayItems = useMemo(() => playerDetails.map((p) => getDisplayItem(p)), [playerDetails]);

  const teamResult = useMemo(() => {
    const result =
      lineup &&
      // clubId && // TODO: 2023 scoring change
      getUfflTeamResult(
        teamName,
        "", // clubId, // TODO: 2023 scoring change
        clubName,
        roundMatches,
        lineup,
        stats,
        displayItems
      );
    return result;
  }, [
    teamName,
    // clubId, // TODO: 2023 scoring change
    clubName,
    roundMatches,
    lineup,
    stats,
    displayItems
  ]);

  return teamResult ? (
    <>
      <TableRow onClick={toggleOpen}>
        <HeaderCell component="th">{teamResult.teamName}</HeaderCell>
        {Object.values(UfflStartingPosition).map((position) => (
          <Cell key={position}>{teamResult.lineup[position].total}</Cell>
        ))}
        {
          // TODO: 2023 scoring change
          // <Cell>{teamResult.club.value}</Cell>
        }
        <Cell>{teamResult.total}</Cell>
      </TableRow>
      <MatchResultsTableRowDetails open={open} toggleOpen={toggleOpen} results={teamResult} />
    </>
  ) : null;
};
