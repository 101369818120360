import { FunctionComponent } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  FormHelperText
} from "@mui/material";

import { LineupPlayer, PlayerDisplayItem } from "../../models/player";
import { UfflPosition } from "../../models/uffl";
import { LineupPickError } from "../../models/lineupSelection";

interface PlayerSelectProps {
  position: UfflPosition;
  pick: LineupPlayer;
  setPick: (position: UfflPosition, pick: LineupPlayer) => void;
  displayItems: PlayerDisplayItem[];
  error: LineupPickError;
  editMode: boolean;
}
export const PlayerSelect: FunctionComponent<PlayerSelectProps> = (props) => {
  const handleChange = (event: SelectChangeEvent) => {
    props.setPick(props.position, {
      ...props.pick,
      playerId: event.target.value
    });
  };

  const isError = props.error !== LineupPickError.NoError;

  return (
    <FormControl
      // fullWidth
      error={isError}
    >
      <InputLabel id="select-label">{props.position}</InputLabel>
      {
        <Select
          labelId="select-label"
          size="small"
          label={props.position}
          value={props.pick.playerId}
          onChange={handleChange}
          disabled={!props.editMode}
        >
          {props.displayItems?.map(({ playerId, displayName }, index) => (
            <MenuItem value={playerId} key={`player-select-item-${index}`}>
              {displayName}
            </MenuItem>
          ))}
        </Select>
      }
      {isError ? <FormHelperText>{props.error}</FormHelperText> : null}
    </FormControl>
  );
};
