export interface PlayerMetadataBase {
  playerId: string;
}

export interface PlayerDisplayItem extends PlayerMetadataBase {
  displayName: string;
}

export const getDisplayName = (playerDetails: PlayerDetails | PlayerDetailsShort): string => {
  return `${playerDetails.surname.toUpperCase()} ${playerDetails.givenName}`;
};

export const getDisplayItem = (p: PlayerDetails): PlayerDisplayItem => {
  return {
    playerId: p.playerId,
    displayName: getDisplayName(p)
  };
};

export interface LineupPlayer extends PlayerMetadataBase {
  substituted?: boolean;
}

export interface PlayerName {
  givenName: string;
  surname: string;
}

export interface PlayerDetailsBase extends PlayerMetadataBase, PlayerName {}

interface PlayerAflTeam {
  teamName: string;
  teamNickname: string;
  teamAbbr: string;
  teamId: string;
}

export interface PlayerDetailsShort extends PlayerDetailsBase {
  // jumper: number;
  jumperNumber: number;
  photoURL: string;
  playerPosition: string;
  // playerName?: PlayerName;
  // team?: PlayerAflTeam;
}

export interface PlayerDetails extends PlayerDetailsBase {
  aflAwards: any;
  age: number;
  bio: any;
  dateOfBirth: string;
  debutYear: string;
  draftPosition: number;
  draftType: string;
  draftYear: string;
  heightCm: number;
  jumperNumber: number;
  kickingFoot: string;
  photoURL: string;
  position: string;
  recruitedFrom: string;
  stateOfOrigin: string;
  weightKg: number;
}

export interface PlayerStatsGroup extends PlayerMetadataBase {
  totals?: PlayerStats;
  player?: PlayerDetailsShort;
  stats?: {
    averages: PlayerStats;
    totals: PlayerStats;
    gamesPlayed: number;
    timeOnGroundPercentage: number;
  };
  team?: PlayerAflTeam;
}

interface PlayerStatsClearance {
  centreClearances: number;
  stoppageClearances: number;
  totalClearances: number;
}

interface PlayerStatsCore {
  playerId?: string;
  behinds: number;
  bounces: number;
  clangers: number;
  contestedMarks: number;
  contestedPossessions: number;
  disposalEfficiency: number;
  disposals: number;
  dreamTeamPoints: number;
  freesAgainst: number;
  freesFor: number;
  goalAccuracy: number;
  goalAssists: number;
  goals: number;
  handballs: number;
  hitouts: number;
  inside50s: number;
  intercepts: number;
  kicks: number;
  marks: number;
  marksInside50: number;
  metresGained: number;
  onePercenters: number;
  ratingPoints: number;
  rebound50s: number;
  scoreInvolvements: number;
  shotsAtGoal: number;
  tackles: number;
  tacklesInside50: number;
  totalPossessions: number;
  turnovers: number;
  uncontestedPossessions: number;
}

export interface PlayerStats extends PlayerStatsCore {
  lastUpdated: any;
  clearances: PlayerStatsClearance;
  goalEfficiency: any;
  interchangeCounts: any;
  ranking: any;
  shotEfficiency: any;
  superGoals: any;
}

export interface PlayerStatsFull extends PlayerStatsCore, PlayerStatsClearance {
  playerId: string;
  centreBounceAttendances: number;
  contestDefLossPercentage: number;
  contestDefLosses: number;
  contestDefOneOnOnes: number;
  contestOffOneOnOnes: number;
  contestOffWins: number;
  contestOffWinsPercentage: number;
  contestedPossessionRate: number;
  defHalfPressureActs: number;
  effectiveDisposals: number;
  effectiveKicks: number;
  f50GroundBallGets: number;
  groundBallGets: number;
  hitoutToAdvantageRate: number;
  hitoutWinPercentage: number;
  hitoutsToAdvantage: number;
  interceptMarks: number;
  kickEfficiency: number;
  kickToHandballRatio: number;
  kickins: number;
  kickinsPlayon: number;
  marksOnLead: number;
  matchesPlayed: number | null;
  pressureActs: number;
  ruckContests: number;
  scoreLaunches: number;
  spoils: number;
  timeOnGroundPercentage: number;
}
