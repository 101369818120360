import { DateTime, Duration, DurationObjectUnits } from "luxon";

export const timestampFormat = "yyyy-MM-dd'T'TT.SSSZZZ";

export const now = () => DateTime.utc().toFormat(timestampFormat);

export const parseTimestamp = (timestamp: string) => {
  return DateTime.fromString(timestamp, timestampFormat);
};

// just in case lol
export const parseTimestampToPerth = (timestamp: string) => {
  return parseTimestamp(timestamp).setZone("Australia/Perth");
};

export const normalizeDuration = (duration: Duration): DurationObjectUnits => {
  return duration
    .shiftTo("days", "hours", "minutes", "seconds")
    .normalize()
    .toObject();
};

export const getCountdown = (
  countdownToTimestamp: string,
  nowTimestamp: string
) => {
  const d = parseTimestamp(countdownToTimestamp).diff(
    parseTimestamp(nowTimestamp)
  );
  return normalizeDuration(d);
};

export const formatCountdown = (durationObjectUnits: DurationObjectUnits) => {
  const d = Duration.fromObject(durationObjectUnits);
  return d.toFormat("d:hh:mm:ss", {
    floor: true
  });
};
