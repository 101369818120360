import { FunctionComponent, useState } from "react";
import { useAuth } from "reactfire";
import { signOut } from "firebase/auth";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItem, ListItemButton, ListItemText } from "@mui/material";

import { useAppSelector } from "../../redux/hooks";
import { ListItemLink } from "./ListItemLink";

interface MenuItemsProps {
  onClose?: () => void;
}

export const MenuItems: FunctionComponent<MenuItemsProps> = ({ onClose }) => {
  const auth = useAuth();
  const currentUser = useAppSelector((state) => state.currentUser);
  const [adminSectionOpen, setAdminSectionOpen] = useState(false);

  const authItem = currentUser.uid ? (
    <ListItemButton
      onClick={() => {
        signOut(auth);
        onClose?.();
      }}
    >
      <ListItemText primary="log out" />
    </ListItemButton>
  ) : (
    <ListItemLink to="/login" primary="login" onClick={onClose} />
  );

  const loggedInItems = (
    <>
      <ListItemLink to="/lineup-selection" primary="lineup selection" onClick={onClose} />
    </>
  );

  const adminSection = (
    <ListItem disablePadding>
      <ListItemText
        disableTypography
        primary={
          <ListItemButton onClick={() => setAdminSectionOpen((prev) => !prev)}>
            <ListItemText primary="admin" />
            {adminSectionOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        }
        secondary={
          <Collapse in={adminSectionOpen} timeout="auto" unmountOnExit sx={{ pl: 4 }}>
            <List disablePadding>
              <ListItemLink to="/lineup-status" primary="lineup status" onClick={onClose} />
              <ListItemLink to="/substitutions" primary="substitutions" onClick={onClose} />
              <ListItemLink to="/list-management" primary="list management" onClick={onClose} />
              <ListItemLink to="/updater-log" primary="updater log" onClick={onClose} />
            </List>
          </Collapse>
        }
      />
    </ListItem>
  );

  return (
    <List>
      {currentUser.uid && loggedInItems}
      {currentUser.isAdmin && adminSection}
      {authItem}
    </List>
  );
};
