import { collection } from "firebase/firestore";
import {
  useFirestore,
  useFirestoreCollectionData,
  ObservableStatus
} from "reactfire";

import { useFirestoreBasePath } from "./useFirestoreBasePath";
import { useCompSeasonId } from "./useCompSeasonId";
import { UfflUserRound, ufflUserRoundConverter } from "../models/round";

// TODO: this should be calling a provider
export const useUfflRoundTeams: (
  roundId: string
) => ObservableStatus<UfflUserRound[]> = (roundId) => {
  const firestoreBasePath = useFirestoreBasePath();
  const compSeasonId = useCompSeasonId();

  const colRef = collection(
    useFirestore(),
    firestoreBasePath,
    "uffl",
    "compSeasons",
    compSeasonId,
    "rounds",
    roundId,
    "teams"
  ).withConverter(ufflUserRoundConverter);

  const result = useFirestoreCollectionData(colRef, { idField: "uid" });

  // TODO: return the colRef as well to pass to other hooks/actions
  return result;
};
