import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { store, RootState, AppDispatch } from "./store";

export const useAppDispatch = (): ThunkDispatch<RootState, null, AnyAction> &
  ThunkDispatch<RootState, undefined, AnyAction> &
  Dispatch<AnyAction> => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppState = (): RootState => store.getState();
