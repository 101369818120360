import { FunctionComponent, useCallback } from "react";
import { Select, selectClasses, outlinedInputClasses, SelectChangeEvent, MenuItem, useTheme } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { themeActions } from "../../slices/themeSlice";

const fontNames = [
  "Inter",
  "Lato",
  "Lora",
  "Manrope",
  "Merriweather",
  "Montserrat",
  "Open Sans",
  "Poppins",
  "Quicksand",
  "Raleway",
  "Roboto",
  "Rubik",
  "Work Sans"
];

export const FontSelect: FunctionComponent = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const googleFontName = useAppSelector((state) => state.theme.googleFontName);

  const handleChange = useCallback(
    (event: SelectChangeEvent) => dispatch(themeActions.setGoogleFontName(event.target.value)),
    [dispatch]
  );

  return (
    <Select
      size="small"
      color="secondary"
      displayEmpty
      value={googleFontName}
      onChange={handleChange}
      sx={{
        [`.${selectClasses.icon}`]: {
          color: theme.palette.primary.contrastText
        },
        [`.${outlinedInputClasses.input}`]: {
          color: theme.palette.primary.contrastText
        },
        [`.${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.primary.contrastText
        },
        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.primary.contrastText
        }
      }}
    >
      <MenuItem disabled value="">
        <em>Select font</em>
      </MenuItem>
      {fontNames.map((fontName) => (
        <MenuItem key={fontName} value={fontName}>
          {fontName}
        </MenuItem>
      ))}
    </Select>
  );
};
