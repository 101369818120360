import { FunctionComponent } from "react";
import { Stack, Typography } from "@mui/material";

import { useAppSelector } from "../../redux/hooks";
import { LineupSelectionData } from "./LineupSelectionData";

export const LineupSelection: FunctionComponent = () => {
  const nextRoundId = useAppSelector(
    (state) => state.roundStatus.nextRound?.providerId
  );
  const currentUser = useAppSelector((state) => state.currentUser);

  return (
    <Stack>
      {currentUser.uid ? null : <Typography>no user</Typography>}
      {nextRoundId ? null : <Typography>no round</Typography>}
      {currentUser.uid && nextRoundId && (
        <LineupSelectionData roundId={nextRoundId} userId={currentUser.uid} />
      )}
    </Stack>
  );
};
