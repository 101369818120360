import { FunctionComponent, useState, useMemo } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { doc } from "firebase/firestore";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { find } from "lodash";

import { useAppSelector } from "../../redux/hooks";
import { useFirestoreBasePath } from "../../hooks/useFirestoreBasePath";
import { usePlayerDetails } from "../../hooks/usePlayerDetails";
import { useCompSeasonId } from "../../hooks/useCompSeasonId";
import { useUfflUsers } from "../../hooks/useUfflUsers";
import { getDisplayItem } from "../../models/player";
import { ufflUserRoundConverter } from "../../models/round";
import { LineupSelectionForm } from "./LineupSelectionForm";

interface LineupSelectionDataProps {
  roundId: string;
  userId: string;
}

export const LineupSelectionData: FunctionComponent<LineupSelectionDataProps> = ({ roundId, userId }) => {
  const compSeasonId = useCompSeasonId();
  const firestoreBasePath = useFirestoreBasePath();
  const playerDetails = usePlayerDetails();
  const ufflUsers = useUfflUsers();

  const [
    editMode
    // setEditMode
  ] = useState(true);

  const rounds = useAppSelector((state) => state.aflRounds.list);
  const round = useMemo(() => find(rounds, ["providerId", roundId]), [rounds, roundId]);
  const user = useMemo(() => find(ufflUsers?.data ?? [], ["uid", userId]), [ufflUsers, userId]);

  const docRef = doc(
    useFirestore(),
    firestoreBasePath,
    "uffl",
    "compSeasons",
    compSeasonId,
    "rounds",
    roundId,
    "teams",
    userId
  ).withConverter(ufflUserRoundConverter);

  const { status, data } = useFirestoreDocData(docRef, { idField: "uid" });

  const displayItems = useMemo(
    () =>
      (playerDetails?.data ?? [])
        .filter((pd) => data?.players?.includes(pd.playerId))
        .map(getDisplayItem)
        .sort((a, b) => a.displayName.localeCompare(b.displayName)),
    [data?.players, playerDetails?.data]
  );

  return status === "loading" ? (
    <CircularProgress />
  ) : (
    <Stack justifyContent="center">
      {round && (
        <Typography variant="h6" align="center">
          Lineup for {round.name}
        </Typography>
      )}
      {user && (
        <Typography sx={{ mb: 2 }} variant="body2" color="text.secondary" component="div" align="center">
          {user.teamName}
        </Typography>
      )}
      <LineupSelectionForm docRef={docRef} lineup={data?.lineup} displayItems={displayItems} editMode={editMode} />
    </Stack>
  );
};
