import { FunctionComponent } from "react";
import { useTheme, Table, TableBody, TableRow, Collapse, Stack, Typography } from "@mui/material";
import { map } from "lodash";
import { UfflPositionResult, UfflTeamResult } from "../../models/scoring";
import { UfflStartingPosition } from "../../models/uffl";
import { Cell, StatDetailText, StatDetailValue } from "./MatchResultsTableStyles";

interface StatDetailItem {
  stat: string;
  value: number;
}

interface StatDetailRowProps {
  categoryText: string;
  headerText: string;
  displayItems: StatDetailItem[];
}

const StatDetailRow: FunctionComponent<StatDetailRowProps> = ({ categoryText, headerText, displayItems }) => {
  const theme = useTheme();
  return (
    <TableRow>
      <Cell>
        <Typography variant="caption" style={{ fontWeight: 600, color: theme.palette.text.secondary }}>
          {categoryText}
        </Typography>
      </Cell>
      <Cell>
        <Typography variant="caption">{headerText}</Typography>
      </Cell>
      <Cell>
        <Stack direction="row" justifyContent="space-around">
          {map(displayItems, (item) => (
            <Stack textAlign="center" key={item.stat}>
              <StatDetailValue>{item.value}</StatDetailValue>
              <StatDetailText>{item.stat}</StatDetailText>
            </Stack>
          ))}
        </Stack>
      </Cell>
    </TableRow>
  );
};

interface PositionResultDisplayItemProps {
  result: UfflPositionResult<UfflStartingPosition>;
  position: UfflStartingPosition;
}

const PositionResultDisplayItem: FunctionComponent<PositionResultDisplayItemProps> = ({ result, position }) => {
  const displayItems = map(result.stats, (res, stat) => ({
    stat,
    value: res.count
  }));
  return <StatDetailRow categoryText={position} headerText={result.player.displayName} displayItems={displayItems} />;
};

interface MatchResultsTableRowDetailsProps {
  open: boolean;
  toggleOpen: () => void;
  results: UfflTeamResult;
}

export const MatchResultsTableRowDetails: FunctionComponent<MatchResultsTableRowDetailsProps> = ({
  open,
  toggleOpen,
  results
}) => {
  // TODO: 2023 scoring change
  // const clubRow = (
  //   <StatDetailRow
  //     categoryText={"CLB"}
  //     headerText={results.club.clubName}
  //     displayItems={[{ stat: "wins", value: results.club.value }]}
  //   />
  // );

  return (
    <TableRow onClick={toggleOpen}>
      <Cell style={{ borderBottom: "unset", paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table>
            <TableBody>
              {Object.entries(results.lineup).map(([pos, res]) => (
                <PositionResultDisplayItem result={res} position={pos as UfflStartingPosition} key={pos} />
              ))}
              {
                // TODO: 2023 scoring change
                // clubRow
              }
            </TableBody>
          </Table>
        </Collapse>
      </Cell>
    </TableRow>
  );
};
