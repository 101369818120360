import { FunctionComponent, useMemo } from "react";
import { TableRow, TableCell, Typography } from "@mui/material";

import { useUfflUsers } from "../../hooks/useUfflUsers";

import { UfflMatch, UfflStartingPosition } from "../../models/uffl";
import { AflMatch } from "../../models/afl";

import { MatchResultsTableRow } from "./MatchResultsTableRow";
import { HeaderCell } from "./MatchResultsTableStyles";

export type MatchResultsTableRowsProps = {
  roundId: string;
  match: UfflMatch;
  roundMatches: AflMatch[];
};

export const MatchResultsTableRows: FunctionComponent<
  MatchResultsTableRowsProps
> = ({ roundId, match, roundMatches }) => {
  const { data: ufflUsers } = useUfflUsers();

  const teams = useMemo(
    () => ufflUsers.filter((u) => Object.values(match).includes(u.uid)),
    [match, ufflUsers]
  );

  return (
    <>
      <TableRow>
        <TableCell align="center" colSpan={10}>
          <Typography variant="body1" pt={2}>
            {teams.map((team) => team.teamName).join(" vs. ")}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <HeaderCell>Team name</HeaderCell>
        {Object.values(UfflStartingPosition).map((position) => {
          return <HeaderCell key={position}>{position}</HeaderCell>;
        })}
        {
          // TODO: 2023 scoring change
          // <HeaderCell>CLB</HeaderCell>
        }
        <HeaderCell>Total</HeaderCell>
      </TableRow>
      {teams.map((team) => (
        <MatchResultsTableRow
          roundId={roundId}
          userId={team.uid}
          teamName={team.teamName}
          roundMatches={roundMatches}
          key={team.uid}
        />
      ))}
    </>
  );
};
