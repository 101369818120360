import { FunctionComponent, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled, Box, Drawer, Typography, Link, IconButton, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { MenuItems } from "./MenuItems";
import { ToolbarMessage } from "./ToolbarMessage";

const ToolbarContainer = styled(Box)`
  position: relative;
  background-color: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 3.5rem;
  color: ${(props) => props.theme.palette.primary.contrastText};
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
`;

const drawerWidth = 240;

export const Toolbar: FunctionComponent = () => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ToolbarContainer>
        <IconButton size="large" edge="start" color="inherit" sx={{ mr: 2 }} onClick={() => setIsOpen(true)}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
          <Link
            component={RouterLink}
            to="/"
            variant="inherit"
            underline="none"
            color={theme.palette.primary.contrastText}
          >
            UFFL
          </Link>
        </Typography>
        <ToolbarMessage />
      </ToolbarContainer>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box"
          }
        }}
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Box sx={{ overflow: "auto" }}>
          <MenuItems onClose={() => setIsOpen(false)} />
        </Box>
      </Drawer>
    </Box>
  );
};
