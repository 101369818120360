import { FunctionComponent, ReactNode } from "react";
import { Navigate, Route, Routes, To } from "react-router-dom";
import { styled, Box } from "@mui/material";

import { CurrentUserListener } from "../listeners/CurrentUserListener";
import { ReactFireListener } from "../listeners/ReactFireListener";
import { RoundStatusListener } from "../listeners/RoundStatusListener";
import { useAppSelector } from "../redux/hooks";

import { LineupStatus } from "./admin/LineupStatus";
import { UpdaterLog } from "./admin/UpdaterLog";
import { LineupSelection } from "./lineupSelection/LineupSelection";
import { ListManagement } from "./listManagement/ListManagement";
import { Toolbar } from "./menu/Toolbar";
import { RoundResults } from "./roundResults/RoundResults";
import { StatusBar } from "./statusBar/StatusBar";
import { Substitutions } from "./substitutions/Substitutions";
import { Home } from "./Home";
import { Login } from "./Login";

const GuiContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
`;

const ContentContainer = styled(Box)`
  position: relative;
  height: 100%;
  align-items: center;
  overflow-y: auto;
  padding: 0.5rem;
  text-align: center;
`;

// for posterity
// interface ProtectedRouteProps {
//   condition: boolean;
//   redirectTo: To;
// }
// const ProtectedRoute: FunctionComponent<PropsWithChildren<ProtectedRouteProps>> = ({ condition, redirectTo, children }) => {
//   return condition ? children : <Navigate to={redirectTo} replace />;
// };

const redirect = (condition: boolean, to: To, node: ReactNode) => {
  return condition ? node : <Navigate to={to} replace />;
};

export const Gui: FunctionComponent = () => {
  const nextRound = useAppSelector((state) => state.roundStatus.nextRound);
  const currentRound = useAppSelector((state) => state.roundStatus.currentRound);
  const currentUser = useAppSelector((state) => state.currentUser);

  return (
    <>
      <CurrentUserListener />
      <ReactFireListener />
      <RoundStatusListener />
      <GuiContainer>
        <Toolbar />
        <ContentContainer>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="round-results" element={<RoundResults />} />
            <Route
              path="lineup-selection"
              element={redirect(Boolean(currentUser?.uid), "/login", <LineupSelection />)}
            />
            <Route
              path="lineup-status"
              element={redirect(currentUser?.isAdmin ?? false, "/", nextRound && <LineupStatus round={nextRound} />)}
            />
            <Route
              path="substitutions"
              element={redirect(currentUser?.isAdmin ?? false, "/", currentRound && <Substitutions />)}
            />
            <Route
              path="list-management"
              element={redirect(currentUser?.isAdmin ?? false, "/", nextRound && <ListManagement round={nextRound} />)}
            />
            <Route path="updater-log" element={redirect(currentUser?.isAdmin ?? false, "/", <UpdaterLog />)} />
            <Route path="*" element={<Home />} />
          </Routes>
        </ContentContainer>
        <StatusBar />
      </GuiContainer>
    </>
  );
};
