import { FunctionComponent, useEffect } from "react";

import { useAppSelector } from "../../redux/hooks";

export const FontWatcher: FunctionComponent = () => {
  const googleFontName = useAppSelector((state) => state.theme.googleFontName);

  useEffect(() => {
    const fontLinkElement = document.getElementById("google-fonts");
    if (!googleFontName || !fontLinkElement) return;
    const fontUrlName = googleFontName.replaceAll(" ", "+");
    const fontUrl = `https://fonts.googleapis.com/css2?family=${fontUrlName}:wght@300;400;500;700`;
    (fontLinkElement as HTMLLinkElement).href = fontUrl;
  }, [googleFontName]);

  return null;
};
