import { FunctionComponent, useMemo, useState, useCallback } from "react";
import { useFirestore } from "reactfire";
import { doc, setDoc } from "firebase/firestore";
import {
  styled,
  Stack,
  Typography,
  Autocomplete,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import { flatten, sortBy } from "lodash";

import { AflRound, UfflUserRound } from "../../models/round";
import { UfflUser } from "../../models/uffl";
import { getDisplayItem, PlayerDisplayItem } from "../../models/player";
import { useUfflRoundTeams } from "../../hooks/useUfflRoundTeams";
import { usePlayerDetails } from "../../hooks/usePlayerDetails";
import { useCompSeasonId } from "../../hooks/useCompSeasonId";
import { useFirestoreBasePath } from "../../hooks/useFirestoreBasePath";

const PlayerName = styled(Typography)`
  padding-top: 0.5rem;
  font-weight: 500;
`;

type AddPlayerProps = {
  round: AflRound;
  user: UfflUser;
  team: UfflUserRound;
};

export const AddPlayer: FunctionComponent<AddPlayerProps> = ({ round, user, team }) => {
  const teams = useUfflRoundTeams(round.providerId);
  const playerDetails = usePlayerDetails();
  const compSeasonId = useCompSeasonId();
  const firestoreBasePath = useFirestoreBasePath();

  const displayItems = useMemo(() => {
    const playerIds = flatten(teams?.data?.map((team) => team.players ?? []));
    const details =
      playerDetails?.data?.filter((pd) => !playerIds?.includes(pd.playerId))?.map((p) => getDisplayItem(p)) ?? [];
    return sortBy(details, ["displayName"]);
  }, [playerDetails.data, teams.data]);

  const [open, setOpen] = useState(false);
  const [player, setPlayer] = useState<PlayerDisplayItem | null>(null);
  const [inputValue, setInputValue] = useState("");

  const docRef = doc(
    useFirestore(),
    firestoreBasePath,
    "uffl",
    "compSeasons",
    compSeasonId,
    "rounds",
    round.providerId,
    "teams",
    user.uid
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(() => {
    if (!player) return;

    const addPlayer = [...(team.players ?? []), player.playerId];
    setDoc(docRef, { players: addPlayer }, { merge: true });

    setOpen(false);
    setPlayer(null);
  }, [docRef, player, team.players]);

  return (
    <>
      <Stack direction="row" spacing={0.5}>
        <Autocomplete
          value={player}
          onChange={(event: any, newValue: PlayerDisplayItem | null) => {
            setPlayer(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={displayItems}
          getOptionLabel={(option) => option.displayName}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Select a player" />}
        />
        <Button variant="contained" onClick={() => setOpen(true)} disabled={!player}>
          Add
        </Button>
      </Stack>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add player?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to add this player?</DialogContentText>
          <PlayerName>{player?.displayName}</PlayerName>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} autoFocus>
            Do it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
