import { Button, Dialog, Link, Stack, TextField, Typography, useTheme } from "@mui/material";
import { FirebaseError } from "firebase/app";
import { AuthErrorCodes, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { FunctionComponent, useCallback, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "reactfire";

const isFirebaseError = (error: any): error is FirebaseError => {
  return (error as FirebaseError).name !== undefined;
};

export const Login: FunctionComponent = () => {
  const theme = useTheme();
  const auth = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [showDialog, setShowDialog] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const resetErrors = useCallback(() => {
    setEmailError("");
    setPasswordError("");
  }, []);

  const openDialog = useCallback(() => {
    resetErrors();
    setEmailSent(false);
    setShowDialog(true);
  }, [resetErrors]);

  const closeDialog = useCallback(() => {
    resetErrors();
    setShowDialog(false);
  }, [resetErrors]);

  const handleSubmit = useCallback(() => {
    resetErrors();
    if (!email || !password) {
      setEmailError(email ? "" : "Email is required.");
      setPasswordError(password ? "" : "Password is required.");
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((userCredential) => {
        // console.log("userCredential", userCredential);
        navigate("/");
      })
      .catch((error) => {
        // console.log("error", { ...error });
        if (isFirebaseError(error)) {
          switch (error.code) {
            case AuthErrorCodes.USER_DELETED:
              setEmailError("Email not found.");
              break;
            case AuthErrorCodes.INVALID_EMAIL:
              setEmailError("Email is invalid.");
              break;
            case AuthErrorCodes.INVALID_PASSWORD:
              setPasswordError("Password is incorrect.");
              break;
            default:
              break;
          }
        }
      });
  }, [email, password, resetErrors, auth, navigate]);

  const handleReset = useCallback(() => {
    resetErrors();
    if (!email) {
      setEmailError("Email is required.");
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setEmailSent(true);
      })
      .catch((error) => {
        // console.log("error", { ...error });
        if (isFirebaseError(error)) {
          switch (error.code) {
            case AuthErrorCodes.USER_DELETED:
              setEmailError("Email not found.");
              break;
            case AuthErrorCodes.INVALID_EMAIL:
              setEmailError("Email is invalid.");
              break;
            default:
              break;
          }
        }
      });
  }, [email, resetErrors, auth]);

  const emailField = useMemo(
    () => (
      <TextField
        margin="dense"
        required
        fullWidth
        value={email}
        onChange={(event) => setEmail(event.currentTarget.value)}
        label="Email"
        autoComplete="email"
        autoFocus
        error={Boolean(emailError)}
        helperText={emailError ? emailError : " "}
      />
    ),
    [email, emailError]
  );

  return (
    <>
      <Stack sx={{ alignItems: "center", mt: "1rem", gap: "1rem" }}>
        <Stack sx={{ width: "100%", alignItems: "center" }}>
          {emailField}
          <TextField
            margin="dense"
            required
            fullWidth
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSubmit();
              }
            }}
            label="Password"
            type="password"
            autoComplete="current-password"
            error={Boolean(passwordError)}
            helperText={passwordError ? passwordError : " "}
          />
        </Stack>
        <Stack sx={{ width: "100%", gap: "1rem", alignItems: "center" }}>
          <Button onClick={handleSubmit} variant="contained">
            Log in
          </Button>
          <Link href="#" onClick={openDialog} variant="body2" color={theme.palette.primary.dark}>
            Forgot password? I gotchu fam
          </Link>
        </Stack>
      </Stack>
      <Dialog open={showDialog} onClose={closeDialog}>
        <Stack sx={{ padding: "1rem", alignItems: "center" }}>
          {emailField}
          <Stack sx={{ width: "100%", gap: "1rem", alignItems: "center" }}>
            <Typography variant="body2">{emailSent ? "Email sent!" : "Want a reset link?"}</Typography>
            <Button onClick={emailSent ? closeDialog : handleReset} variant="contained">
              {emailSent ? "Back" : "Send it"}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
