import {
  FunctionComponent,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo
} from "react";
import { collection, FirestoreDataConverter } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import _ from "lodash";

import { useAppSelector } from "../redux/hooks";
import { useFirestoreBasePath } from "../hooks/useFirestoreBasePath";
import { useCompSeasonId } from "../hooks/useCompSeasonId";
import { PlayerStatsFull } from "../models/player";

export type RoundPlayerStatsContextType = {
  roundId: string;
  setRoundId: Dispatch<SetStateAction<string>>;
  playerStats: PlayerStatsFull[];
};

const defaultRoundPlayerStatsContext: RoundPlayerStatsContextType = {
  roundId: "placeholderRoundId",
  setRoundId: () => {
    throw new Error(
      "This function must be used inside a RoundPlayerStatsProvider."
    );
  },
  playerStats: []
};

export const RoundPlayerStatsContext =
  createContext<RoundPlayerStatsContextType>(defaultRoundPlayerStatsContext);

// TODO: this should be generic
export const playerStatsFullConverter: FirestoreDataConverter<PlayerStatsFull> =
  {
    toFirestore: (object) => _.mapValues(object, (value) => value),
    fromFirestore: (snapshot, options) =>
      snapshot.data(options) as PlayerStatsFull
  };

export const RoundPlayerStatsProvider: FunctionComponent = (props) => {
  const firestoreBasePath = useFirestoreBasePath();
  const compSeasonId = useCompSeasonId();
  const currentRound = useAppSelector(
    (state) => state.roundStatus.currentRound
  );
  const [roundId, setRoundId] = useState(
    currentRound?.providerId ?? defaultRoundPlayerStatsContext.roundId
  );

  useEffect(() => {
    if (!currentRound?.providerId) return;
    if (roundId === currentRound.providerId) return;
    if (roundId === defaultRoundPlayerStatsContext.roundId) {
      setRoundId(currentRound.providerId);
    }
  }, [currentRound?.providerId, roundId]);

  const colRef = collection(
    useFirestore(),
    firestoreBasePath,
    "afl",
    "compSeasons",
    compSeasonId,
    "rounds",
    roundId,
    "playerStats"
  ).withConverter(playerStatsFullConverter);

  const result = useFirestoreCollectionData(colRef, {
    idField: "playerId"
  });

  const playerStats = useMemo(() => result.data ?? [], [result.data]);

  return (
    <RoundPlayerStatsContext.Provider
      value={{
        roundId,
        setRoundId,
        playerStats
      }}
    >
      {props.children}
    </RoundPlayerStatsContext.Provider>
  );
};
