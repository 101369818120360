import {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
  useMemo
} from "react";
import { DocumentReference, setDoc } from "firebase/firestore";
import { Stack, Button, Table, TableBody } from "@mui/material";

import {
  UfflPosition,
  UfflReservePosition,
  subsPositions,
  Lineup
} from "../../models/uffl";
import { SubstitutionToggle } from "./SubstitutionToggle";

type SubstitutionsFormProps = {
  docRef: DocumentReference;
  lineup: Lineup;
};
export const SubstitutionsForm: FunctionComponent<SubstitutionsFormProps> = ({
  docRef,
  lineup
}) => {
  const [newLineup, setNewLineup] = useState(lineup);

  useEffect(() => setNewLineup(lineup), [lineup]);

  const doChange = useCallback(
    (action: { position: UfflPosition; substituted: boolean }) => {
      setNewLineup((prevLineup) => ({
        ...prevLineup,
        [action.position]: {
          ...prevLineup[action.position],
          substituted: action.substituted
        }
      }));
    },
    []
  );

  const lineupChanged = useMemo(
    () => lineup !== newLineup,
    [lineup, newLineup]
  );

  const handleSubmit = useCallback(() => {
    if (!lineupChanged) return;
    setDoc(docRef, { lineup: newLineup }, { merge: true });
  }, [newLineup, lineupChanged, docRef]);

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Stack spacing={2}>
        <Table size="small">
          <TableBody>
            {Object.keys(subsPositions).map((reservePosition) => (
              <SubstitutionToggle
                reservePosition={reservePosition as UfflReservePosition}
                lineup={newLineup}
                doChange={doChange}
                key={reservePosition}
              />
            ))}
          </TableBody>
        </Table>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!lineupChanged}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
