import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import _ from "lodash";
import { UfflUser } from "../models/uffl";

type CreateMutable<Type> = {
  -readonly [Property in keyof Type]: Type[Property];
};

export interface CurrentUserState
  extends Partial<UfflUser>,
    Partial<CreateMutable<User>> {}

const initialState: CurrentUserState = {
  uid: undefined
};

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<User | null>) => {
      // console.log("doing setAuthData", action.payload);
      if (action.payload == null) {
        Object.keys(state).forEach((key) => {
          _.unset(state, key);
        });
        return;
      }
      state.uid = action.payload.uid;
      state.displayName = action.payload.displayName;
      state.email = action.payload.email;
      // console.log("state", state);
    },
    setUfflData: (state, action: PayloadAction<UfflUser | null>) => {
      if (action.payload) {
        state = _.assign(state, action.payload);
      }
    }
  }
});

export const currentUserActions = currentUserSlice.actions;
export default currentUserSlice.reducer;
