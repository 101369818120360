import { collection, FirestoreDataConverter } from "firebase/firestore";
import {
  useFirestore,
  useFirestoreCollectionData,
  ObservableStatus
} from "reactfire";
import _ from "lodash";

import { useFirestoreBasePath } from "./useFirestoreBasePath";
import { AflMatch } from "../models/afl";

// TODO: this should be generic
export const aflMatchConverter: FirestoreDataConverter<AflMatch> = {
  toFirestore: (object) => _.mapValues(object, (value) => value),
  fromFirestore: (snapshot, options) => snapshot.data(options) as AflMatch
};

// TODO: this should be calling a provider
export const useAflMatches: () => ObservableStatus<AflMatch[]> = () => {
  const firestoreBasePath = useFirestoreBasePath();

  const colRef = collection(
    useFirestore(),
    firestoreBasePath,
    "afl",
    "matches"
  ).withConverter(aflMatchConverter);

  const result = useFirestoreCollectionData(colRef, { idField: "providerId" });

  return result;
};
