import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AflIdPair } from "../models/afl";
import { ScoringSeason } from "../models/scoring";
import { now } from "../utils/datetime";

const compSeasonIds: Record<ScoringSeason, AflIdPair> = {
  [ScoringSeason.CD_S2022014]: { id: 43, providerId: "CD_S2022014" },
  [ScoringSeason.CD_S2023014]: { id: 52, providerId: "CD_S2023014" },
  [ScoringSeason.CD_S2024014]: { id: 62, providerId: "CD_S2024014" }
};

enum AflIdPairType {
  Competition = "competition",
  CompSeason = "compSeason"
}

export interface AppConfigState {
  firestoreBasePath: string;
  aflIds: Record<AflIdPairType, AflIdPair>;
  utcNow: string;
  doClock: boolean;
  devMode: {
    enabled: boolean;
    count: number;
  };
}

const initialState: AppConfigState = {
  aflIds: {
    [AflIdPairType.Competition]: { id: 1, providerId: "CD_C014" },
    [AflIdPairType.CompSeason]: compSeasonIds[ScoringSeason.CD_S2024014]
  },
  firestoreBasePath: "prod",
  utcNow: now(),
  doClock: true,
  devMode: {
    enabled: false,
    count: 0
  }
};

export const appConfigSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    setCompSeason: (state, action: PayloadAction<ScoringSeason>) => {
      state.aflIds.compSeason = { ...compSeasonIds[action.payload] };
    },
    setUtcNow: (state, action: PayloadAction<string>) => {
      if (!action?.payload) return;
      state.utcNow = action.payload;
    },
    autoSetUtcNow: (state) => {
      state.utcNow = now();
    },
    setDoClock: (state, action: PayloadAction<boolean>) => {
      state.doClock = action.payload;
    },
    incrementDevModeCount: (state) => {
      state.devMode.count += 1;
      if (state.devMode.enabled || state.devMode.count < 7) return;
      state.devMode.enabled = true;
    }
  }
});

export const appConfigActions = appConfigSlice.actions;
export default appConfigSlice.reducer;
