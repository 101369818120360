import { FunctionComponent } from "react";
import { Alert } from "@mui/material";

import { useAppSelector } from "../../redux/hooks";
import { getCountdown, formatCountdown } from "../../utils/datetime";

export const RoundEndingAlert: FunctionComponent = () => {
  const nextRound = useAppSelector((state) => state.roundStatus.nextRound);

  const timeUntilStart = useAppSelector(
    (state) =>
      state.roundStatus.nextRound &&
      getCountdown(
        state.roundStatus.nextRound.utcStartTime,
        state.appConfig.utcNow
      )
  );

  const countdown = timeUntilStart && formatCountdown(timeUntilStart);

  if (nextRound) {
    return (
      <Alert severity="warning" sx={{ justifyContent: "center" }}>
        {nextRound.name} starts in {countdown}
      </Alert>
    );
  }
  return null;
};
