import { AflRound } from "./round";

export interface AflIdPair {
  id: number;
  providerId: string;
}

export interface AflClub extends AflIdPair {
  name: string;
  nickname: string;
  abbreviation: string;
}

export interface AflTeam extends AflIdPair {
  name: string;
  nickname: string;
  abbreviation: string;
  teamType: string;
  club: AflClub;
}

export interface AflCompetition extends AflIdPair {
  code: string;
  name: string;
}

export interface AflSeason {
  year: number;
  id: number;
}

export interface AflCompSeason extends AflIdPair {
  name: string;
  shortName: string;
  currentRoundNumber: number;
  rounds: AflRound;
  competition: AflCompetition;
  season: AflSeason;
}

export interface AflMatchScore {
  superGoals: number;
  goals: number;
  totalScore: number;
  behinds: number;
}

export interface AflVenue extends AflIdPair {
  name: string;
  abbreviation: string;
  timezone: string;
  location: string;
  state: string;
}

export enum AflRoundStatus {
  UPCOMING = "UPCOMING",
  LIVE = "LIVE",
  COMPLETED = "COMPLETED",
  NO_MATCHES = "NO_MATCHES"
}

export enum AflMatchStatus {
  POSTPONED = "POSTPONED",
  CANCELLED = "CANCELLED",
  SCHEDULED = "SCHEDULED",
  UNCONFIRMED_TEAMS = "UNCONFIRMED_TEAMS",
  CONFIRMED_TEAMS = "CONFIRMED_TEAMS",
  UPCOMING = "UPCOMING",
  LIVE = "LIVE",
  POSTGAME = "POSTGAME",
  CONCLUDED = "CONCLUDED",
  COMPLETED = "COMPLETED"
}

export interface AflMatchParticipant {
  team: AflTeam;
  score?: AflMatchScore;
  conference?: string;
}
export interface AflMatch extends AflIdPair {
  utcStartTime: string;
  status: AflMatchStatus;
  round: AflRound;
  compSeason: AflCompSeason;
  venue: AflVenue;
  home: AflMatchParticipant;
  away: AflMatchParticipant;
  metadata?: any;
}
