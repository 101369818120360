import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { produce } from "immer";
import _ from "lodash";

import { PlayerStatsGroup } from "../models/player";
import { AflRound } from "../models/round";

interface PlayerStatsUpdateAction {
  roundId: string;
  playerStats: PlayerStatsGroup;
}

interface AflRoundsState {
  list: AflRound[];
}

const initialState: AflRoundsState = {
  list: []
};

export const aflRoundsSlice = createSlice({
  name: "aflRounds",
  initialState: initialState,
  reducers: {
    overwriteRounds: (state, action: PayloadAction<AflRound[] | undefined>) => {
      if (!action.payload) {
        state.list = [];
        return;
      }
      state.list = action.payload;
    },
    updateRounds: (state, action: PayloadAction<AflRound[] | undefined>) => {
      const updated = produce(state.list, (draft) => {
        if (!action.payload) return;
        action.payload.forEach((newRound) => {
          const index = draft.findIndex(
            (res) => res.providerId === newRound.providerId
          );
          if (index !== -1) {
            draft[index] = _.merge(draft[index], newRound);
          } else {
            draft.push(newRound);
          }
        });
      });
      state.list = updated;
    },
    updatePlayerStatsSingle: (
      state,
      action: PayloadAction<PlayerStatsUpdateAction>
    ) => {
      const updated = produce(state.list, (draft) => {
        const playerStatsFull = action.payload.playerStats;
        const playerStats: PlayerStatsGroup = {
          playerId: playerStatsFull.playerId,
          player: playerStatsFull.player,
          totals: playerStatsFull.stats?.totals
        };
        const roundId = action.payload.roundId;
        const roundIndex = draft.findIndex((res) => res.providerId === roundId);
        if (roundIndex !== -1) {
          // found the round in the list
          const round = draft[roundIndex];
          // get the stats list for the round, create if not exists
          if (!round.playerStats) {
            round.playerStats = [];
          }
          const statsList = round.playerStats;
          // now go looking for the player in the stats list
          const playerIndex = statsList.findIndex(
            (res) => res.playerId === playerStats.playerId
          );
          if (playerIndex !== -1) {
            // found the player, update the existing entry
            statsList[playerIndex] = _.merge(
              statsList[playerIndex],
              playerStats
            );
          } else {
            // didn't find the player, add a new entry
            statsList.push(playerStats);
          }
        } else {
          // didn't find the round, wtf
          console.log(
            `Couldn't update stats for playerId ${playerStats.playerId}: roundId ${roundId} not found in aflRoundsSlice.`
          );
        }
      });
      state.list = updated;
    }
  }
});

export const aflRoundsActions = aflRoundsSlice.actions;

export default aflRoundsSlice.reducer;
