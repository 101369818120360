import { render } from "react-dom";
import { Provider } from "react-redux";
import { FirebaseAppProvider } from "reactfire";

import { firebaseConfig } from "./firebase";
import { store } from "./redux/store";
import { App } from "./app/App";

const rootElement = document.getElementById("root");
render(
  <Provider store={store}>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  </Provider>,
  rootElement
);
