import { FunctionComponent, useState, useMemo } from "react";
import { Stack, MenuItem, Select, Typography } from "@mui/material";

import { useAppSelector } from "../../redux/hooks";
import { useUfflUsers } from "../../hooks/useUfflUsers";
import { SubstitutionsData } from "./SubstitutionsData";
import { filter, find } from "lodash";

export const Substitutions: FunctionComponent = () => {
  const ufflUsers = useUfflUsers();
  const allRounds = useAppSelector((state) => state.aflRounds.list);
  const currentRound = useAppSelector((state) => state.roundStatus.currentRound);
  const [selectedRoundId, setSelectedRoundId] = useState(currentRound?.providerId ?? "");
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const selectedUser = useMemo(
    () => selectedUserId && find(ufflUsers.data, ["uid", selectedUserId]),
    [ufflUsers, selectedUserId]
  );

  const rounds = useMemo(
    () => (currentRound ? filter(allRounds, (r) => r.providerId <= currentRound.providerId) : []),
    [currentRound, allRounds]
  );

  const form = useMemo(() => {
    if (!selectedUser || selectedUserId == "" || selectedRoundId == "") {
      return null;
    }

    return <SubstitutionsData roundId={selectedRoundId} user={selectedUser} />;
  }, [selectedRoundId, selectedUser, selectedUserId]);

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Substitutions</Typography>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Select
          displayEmpty
          value={selectedRoundId}
          onChange={(e) => {
            setSelectedRoundId(e.target.value);
          }}
          size="small"
        >
          <MenuItem disabled value="">
            <em>Select a round</em>
          </MenuItem>
          {rounds.map((round) => (
            <MenuItem value={round.providerId} key={round.providerId}>
              {round.name}
            </MenuItem>
          ))}
        </Select>
        <Select
          displayEmpty
          value={selectedUserId}
          onChange={(e) => {
            setSelectedUserId(e.target.value);
          }}
          size="small"
        >
          <MenuItem disabled value="">
            <em>Select a user</em>
          </MenuItem>
          {ufflUsers.data?.map((user) => (
            <MenuItem value={user.uid} key={user.uid}>
              {user.teamName}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      {form}
    </Stack>
  );
};
