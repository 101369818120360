import { FunctionComponent, useEffect, useMemo } from "react";
import { useUser, useAuth } from "reactfire";
import { onAuthStateChanged } from "firebase/auth";
import _ from "lodash";

import { useAppDispatch } from "../redux/hooks";
import { useUfflUsers } from "../hooks/useUfflUsers";

import { currentUserActions } from "../slices/currentUserSlice";

export const CurrentUserListener: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const authUser = useUser();
  const ufflUsers = useUfflUsers();
  const ufflUser = useMemo(
    () => _.find(ufflUsers.data, ["uid", authUser.data?.uid]) ?? null,
    [ufflUsers.data, authUser.data?.uid]
  );

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // console.log("logged in");
      dispatch(currentUserActions.setAuthData(user));
    } else {
      // console.log("logged out");
      dispatch(currentUserActions.setAuthData(null));
    }
  });

  useEffect(() => {
    // console.log("dispatching setAuthData", authUser.data);
    dispatch(currentUserActions.setAuthData(authUser.data));
  }, [authUser, dispatch]);

  useEffect(() => {
    dispatch(currentUserActions.setUfflData(ufflUser));
  }, [ufflUser, dispatch]);

  return null;
};
