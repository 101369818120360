import { styled, TableCell, Typography } from "@mui/material";

export const Cell = styled(TableCell)`
  text-align: center;
  padding: 0.2rem;
  font-size: 0.8rem;
`;

export const HeaderCell = styled(Cell)`
  font-weight: 600;
`;

const StatDetail = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1.1;
`;

export const StatDetailValue = styled(StatDetail)``;

export const StatDetailText = styled(StatDetail)`
  font-size: 0.65rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;
