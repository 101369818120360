import { FunctionComponent, forwardRef, useMemo } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { ListItem, ListItemText } from "@mui/material";

interface ListItemLinkProps {
  primary: string;
  to: string;
  onClick?: () => void;
}

export const ListItemLink: FunctionComponent<ListItemLinkProps> = ({ primary, to, onClick }) => {
  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <ListItem button component={renderLink} onClick={onClick}>
      <ListItemText primary={primary} />
    </ListItem>
  );
};
