import {
  FunctionComponent,
  useState,
  useEffect,
  useReducer,
  useMemo
} from "react";
import { DocumentReference, setDoc } from "firebase/firestore";
import { Button, Stack } from "@mui/material";
import _ from "lodash";

import { UfflPosition, UfflPositions, Lineup } from "../../models/uffl";
import { LineupPlayer, PlayerDisplayItem } from "../../models/player";
import {
  emptyLineup,
  emptyLineupValidation,
  LineupValidation,
  LineupPickError
} from "../../models/lineupSelection";
import { PlayerSelect } from "./PlayerSelect";

export type LineupSelectionFormProps = {
  docRef: DocumentReference;
  lineup?: Lineup;
  displayItems: PlayerDisplayItem[];
  editMode: boolean;
};
export const LineupSelectionForm: FunctionComponent<
  LineupSelectionFormProps
> = (props) => {
  const [lineup, setLineup] = useReducer(
    (state: Lineup, action: { position: UfflPosition; pick: LineupPlayer }) => {
      // console.log("setLineup", action);
      const newState = { ...state, [action.position]: action.pick };
      // console.log("newState", newState);
      return newState;
    },
    props.lineup ?? emptyLineup
  );

  const setPick = (position: UfflPosition, pick: LineupPlayer): void => {
    // console.log(position, pick);
    setLineup({ position, pick });
  };

  const [lineupValidation, setLineupValidation] = useReducer(
    (
      state: LineupValidation,
      action: { position: UfflPosition; error: LineupPickError }
    ) => {
      return { ...state, [action.position]: action.error };
    },
    emptyLineupValidation
  );

  const [passedValidation, setPassedValidation] = useState(false);

  const lineupChanged = useMemo(
    () => !_.isEqual(lineup, props.lineup),
    [lineup, props.lineup]
  );

  const handleSubmit = () => {
    if (!passedValidation) return;
    setDoc(props.docRef, { lineup: lineup }, { merge: true });
  };

  useEffect(() => {
    Object.entries(lineup).forEach(([pos, pick]) => {
      const position = pos as UfflPosition;
      if (pick.playerId === "") {
        setLineupValidation({ position, error: LineupPickError.NoPlayer });
        return;
      }
      if (!_.some(props.displayItems, ["playerId", pick.playerId])) {
        setLineupValidation({ position, error: LineupPickError.InvalidPlayer });
        return;
      }

      const selectedPlayerIds = Object.values(lineup).map((p) => p.playerId);
      const timesPlayerIdSelected = selectedPlayerIds.filter(
        (id) => id === pick.playerId
      ).length;
      if (timesPlayerIdSelected > 1) {
        setLineupValidation({
          position,
          error: LineupPickError.DuplicatePlayer
        });
        return;
      }

      setLineupValidation({ position, error: LineupPickError.NoError });
    });
  }, [lineup, props.displayItems]);

  useEffect(() => {
    // TODO: lodash?
    const errorStatusesFound = Object.values(lineupValidation).filter(
      (status) => status !== LineupPickError.NoError
    );
    // TODO: make this work and use it instead lol
    // const passed = _.every(lineupValidation, LineupPickError.NoError);
    // console.log("foo", passed, errorStatusesFound.length);
    if (errorStatusesFound.length > 0) {
      setPassedValidation(false);
      return;
    }
    setPassedValidation(true);
  }, [lineupValidation]);

  // const debugStuff = (
  //   <>
  //     <div>passedValidation: {JSON.stringify(passedValidation)}</div>
  //     <div>lineupChanged: {JSON.stringify(lineupChanged)}</div>
  //     <div>lineup: {JSON.stringify(lineup)}</div>
  //     <div>props.lineup: {JSON.stringify(props.lineup)}</div>
  //   </>
  // );

  return (
    <Stack spacing={2}>
      {Object.keys(UfflPositions).map((pos) => {
        const position = pos as UfflPosition;
        return (
          <PlayerSelect
            position={position}
            pick={lineup[position]}
            setPick={setPick}
            displayItems={props.displayItems}
            error={lineupValidation[position]}
            editMode={props.editMode}
            key={position}
          />
        );
      })}
      <Button
        onClick={handleSubmit}
        variant="contained"
        disabled={!passedValidation || !lineupChanged}
      >
        Submit
      </Button>
    </Stack>
  );
};
