import { FunctionComponent, useMemo, useState, useCallback } from "react";
import { useFirestore } from "reactfire";
import { doc, setDoc } from "firebase/firestore";
import {
  styled,
  List,
  ListItemButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography
} from "@mui/material";
import { sortBy } from "lodash";

import { AflRound, UfflUserRound } from "../../models/round";
import { UfflUser } from "../../models/uffl";
import { getDisplayItem, PlayerDisplayItem } from "../../models/player";
import { useCompSeasonId } from "../../hooks/useCompSeasonId";
import { useFirestoreBasePath } from "../../hooks/useFirestoreBasePath";
import { usePlayerDetails } from "../../hooks/usePlayerDetails";

const PlayerName = styled(Typography)`
  padding-top: 0.5rem;
  font-weight: 500;
`;

type RemovePlayerProps = {
  round: AflRound;
  user: UfflUser;
  team: UfflUserRound;
};
export const RemovePlayer: FunctionComponent<RemovePlayerProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [player, setPlayer] = useState<PlayerDisplayItem | null>(null);

  const compSeasonId = useCompSeasonId();
  const firestoreBasePath = useFirestoreBasePath();
  const playerDetails = usePlayerDetails();
  const displayItems = useMemo(() => {
    const details =
      (props.team.players &&
        playerDetails.data &&
        playerDetails.data
          .filter((pd) => props.team.players?.includes(pd.playerId))
          .map((p) => getDisplayItem(p))) ??
      [];
    return sortBy(details, ["displayName"]);
  }, [props.team.players, playerDetails.data]);

  const docRef = doc(
    useFirestore(),
    firestoreBasePath,
    "uffl",
    "compSeasons",
    compSeasonId,
    "rounds",
    props.round.providerId,
    "teams",
    props.user.uid
  );

  const handleSelect = (player: PlayerDisplayItem) => {
    setPlayer(player);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(() => {
    if (!player) return;
    if (!props.team.players) return;

    const removePlayer = props.team.players.filter(
      (p) => p !== player.playerId
    );

    setDoc(docRef, { players: removePlayer }, { merge: true });

    setOpen(false);
  }, [docRef, player, props.team.players]);

  return (
    <>
      <List>
        {displayItems.map((item) => (
          <ListItemButton
            onClick={() => handleSelect(item)}
            key={item.playerId}
          >
            {item.displayName}
          </ListItemButton>
        ))}
      </List>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Remove player?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this player?
          </DialogContentText>
          <DialogContentText>This cannot be undone!</DialogContentText>
          <PlayerName>{player?.displayName}</PlayerName>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} autoFocus>
            Do it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
