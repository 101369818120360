import { collection, FirestoreDataConverter } from "firebase/firestore";
import {
  useFirestore,
  useFirestoreCollectionData,
  ObservableStatus
} from "reactfire";
import _ from "lodash";

import { useFirestoreBasePath } from "./useFirestoreBasePath";
import { useCompSeasonId } from "./useCompSeasonId";
import { PlayerDetails } from "../models/player";

// TODO: this should be generic
export const playerDetailsConverter: FirestoreDataConverter<PlayerDetails> = {
  toFirestore: (object) => _.mapValues(object, (value) => value),
  fromFirestore: (snapshot, options) => snapshot.data(options) as PlayerDetails
};

// TODO: this should be calling a provider
export const usePlayerDetails: () => ObservableStatus<PlayerDetails[]> = () => {
  const firestoreBasePath = useFirestoreBasePath();
  const compSeasonId = useCompSeasonId();

  const colRef = collection(
    useFirestore(),
    firestoreBasePath,
    "afl",
    "compSeasons",
    compSeasonId,
    "players"
  ).withConverter(playerDetailsConverter);

  const result = useFirestoreCollectionData(colRef, { idField: "playerId" });

  // TODO: return the colRef as well to pass to other hooks/actions
  return result;
};
