import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { produce } from "immer";
import _ from "lodash";

import { UfflRound } from "../models/round";

interface UfflRoundsState {
  list: UfflRound[];
}

const initialState: UfflRoundsState = {
  list: []
};

export const ufflRoundsSlice = createSlice({
  name: "ufflRounds",
  initialState: initialState,
  reducers: {
    overwriteRounds: (
      state,
      action: PayloadAction<UfflRound[] | undefined>
    ) => {
      if (!action.payload) {
        state.list = [];
        return;
      }
      state.list = action.payload;
    },
    updateRounds: (state, action: PayloadAction<UfflRound[] | undefined>) => {
      const updated = produce(state.list, (draft) => {
        if (!action.payload) return;
        action.payload.forEach((newRound) => {
          const index = draft.findIndex(
            (res) => res.providerId === newRound.providerId
          );
          if (index !== -1) {
            const merged = _.merge(draft[index], newRound);
            draft[index] = merged;
          } else {
            draft.push(newRound);
          }
        });
      });
      state.list = updated;
    }
  }
});

export const ufflRoundsActions = ufflRoundsSlice.actions;

export default ufflRoundsSlice.reducer;
