import { FunctionComponent, useCallback } from "react";
import {
  styled,
  Stack,
  Typography,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";
import { find } from "lodash";

import { AflRound, UfflUserRound } from "../../models/round";
import { useUfflUsers } from "../../hooks/useUfflUsers";
import { useUfflRoundTeams } from "../../hooks/useUfflRoundTeams";

const Cell = styled(TableCell)`
  text-align: center;
  padding: 0.2rem;
`;

const HeaderCell = styled(Cell)`
  font-weight: 600;
`;

interface LineupStatusProps {
  round: AflRound;
}

export const LineupStatus: FunctionComponent<LineupStatusProps> = ({ round }) => {
  const ufflUsers = useUfflUsers();
  const teams = useUfflRoundTeams(round.providerId);

  const teamData = useCallback(
    (team: UfflUserRound) =>
      find(ufflUsers.data, ["uid", team.uid]) ?? {
        uid: team.uid,
        teamName: "???"
      },
    [ufflUsers]
  );

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Stack justifyContent="center" alignItems="center">
        <Typography variant="h6">Lineup Status</Typography>
        <Typography variant="body2" color="text.secondary" component="div" align="center">
          {round.name}
        </Typography>
        <TableContainer component={Container}>
          <Table>
            <TableHead>
              <TableRow sx={{ "td, th": { border: 0 } }}>
                <HeaderCell>Team name</HeaderCell>
                <HeaderCell>Submitted</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teams.data?.map((team) => (
                <TableRow key={team.uid} sx={{ "td, th": { border: 0 } }}>
                  <Cell>{teamData(team).teamName}</Cell>
                  <Cell>{team.lineup ? "✔️" : "❌"}</Cell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};
