import { collection, query, orderBy, limit, FirestoreDataConverter } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { FunctionComponent } from "react";
import { styled, Box, Stack, Table, TableHead, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { mapValues } from "lodash";

import { useFirestoreBasePath } from "../../hooks/useFirestoreBasePath";
import { parseTimestamp } from "../../utils/datetime";

const TableWrapper = styled(Box)`
  max-width: 100%;
  overflow-x: auto;
`;

const Cell = styled(TableCell)`
  white-space: nowrap;
  padding: 0.2rem;
`;

const HeaderCell = styled(Cell)`
  font-weight: 600;
`;

interface LogEntry {
  message: string;
  utc: string;
}

const logEntryConverter: FirestoreDataConverter<LogEntry> = {
  toFirestore: (object) => mapValues(object, (value) => value),
  fromFirestore: (snapshot, options) => snapshot.data(options) as LogEntry
};

export const UpdaterLog: FunctionComponent = () => {
  const firestoreBasePath = useFirestoreBasePath();

  const colRef = collection(useFirestore(), firestoreBasePath, "app", "updaterLog").withConverter(logEntryConverter);

  const entries = useFirestoreCollectionData(query(colRef, orderBy("utc", "desc"), limit(50)));

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography variant="h6">Updater Log</Typography>
      <TableWrapper>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ "td, th": { border: 0 } }}>
              <HeaderCell>Date/time</HeaderCell>
              <HeaderCell>Message</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.data?.map((entry) => (
              <TableRow key={entry.utc} sx={{ "td, th": { border: 0 } }}>
                <Cell>{parseTimestamp(entry.utc).toFormat("yyyy-MM-dd HH:mm:ss.SSS")}</Cell>
                <Cell>{entry.message}</Cell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </Stack>
  );
};
