import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import appConfigReducer from "../slices/appConfigSlice";
import currentUserReducer from "../slices/currentUserSlice";
import aflRoundsReducer from "../slices/aflRoundsSlice";
import roundStatusReducer from "../slices/roundStatusSlice";
import themeReducer from "../slices/themeSlice";
import ufflRoundsReducer from "../slices/ufflRoundsSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  reducer: {
    appConfig: appConfigReducer,
    currentUser: currentUserReducer,
    aflRounds: aflRoundsReducer,
    roundStatus: roundStatusReducer,
    theme: themeReducer,
    ufflRounds: ufflRoundsReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
