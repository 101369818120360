import { FunctionComponent, useCallback, useState, useMemo, MouseEvent } from "react";
import { Stack, Typography, Select, MenuItem, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { find } from "lodash";

import { AflRound } from "../../models/round";
import { useUfflUsers } from "../../hooks/useUfflUsers";
import { useUfflRoundTeams } from "../../hooks/useUfflRoundTeams";

import { AddPlayer } from "./AddPlayer";
import { RemovePlayer } from "./RemovePlayer";

/*
need:
list of all the players in the round to filter out from add list
*/

enum ListManagementView {
  AddPlayer = "Add Player",
  RemovePlayer = "Remove Player"
}

interface ListManagementProps {
  round: AflRound;
}

export const ListManagement: FunctionComponent<ListManagementProps> = ({ round }) => {
  const ufflUsers = useUfflUsers();
  const teams = useUfflRoundTeams(round.providerId);

  const [currentView, setCurrentView] = useState(ListManagementView.RemovePlayer);
  const onChange = useCallback((event: MouseEvent<HTMLElement>, newView: ListManagementView | null) => {
    if (newView !== null) {
      setCurrentView(newView);
    }
  }, []);

  const [selectedUserId, setSelectedUserId] = useState("");

  const selectedUser = useMemo(
    () => (selectedUserId ? find(ufflUsers.data, ["uid", selectedUserId]) : undefined),
    [ufflUsers, selectedUserId]
  );

  const selectedTeam = useMemo(
    () => (selectedUserId ? find(teams.data, ["uid", selectedUserId]) ?? { uid: selectedUserId } : undefined),
    [teams, selectedUserId]
  );

  const view = useMemo(() => {
    switch (currentView) {
      case ListManagementView.AddPlayer:
        if (!selectedUser) {
          return <Typography>Select a user</Typography>;
        }
        if (!selectedTeam) {
          return <Typography>No team data found</Typography>;
        }
        return <AddPlayer user={selectedUser} round={round} team={selectedTeam} />;
      case ListManagementView.RemovePlayer:
        if (!selectedUser) {
          return <Typography>Select a user</Typography>;
        }
        if (!selectedTeam) {
          return <Typography>No team data found</Typography>;
        }
        return <RemovePlayer user={selectedUser} team={selectedTeam} round={round} />;
    }
  }, [currentView, selectedUser, selectedTeam, round]);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
      <Typography variant="h6">List Management</Typography>
      <Typography variant="body1">Changes will take effect in {round.name}</Typography>
      <Select
        displayEmpty
        value={selectedUserId}
        onChange={(e) => {
          setSelectedUserId(e.target.value);
        }}
        size="small"
      >
        <MenuItem disabled value="">
          <em>Select a user</em>
        </MenuItem>
        {ufflUsers.data?.map((user) => (
          <MenuItem value={user.uid} key={user.uid}>
            {user.teamName}
          </MenuItem>
        ))}
      </Select>
      <ToggleButtonGroup exclusive value={currentView} onChange={onChange} color="primary">
        <ToggleButton value={ListManagementView.RemovePlayer}>{ListManagementView.RemovePlayer}</ToggleButton>
        <ToggleButton value={ListManagementView.AddPlayer}>{ListManagementView.AddPlayer}</ToggleButton>
      </ToggleButtonGroup>
      {view}
    </Stack>
  );
};
