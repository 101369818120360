import { FunctionComponent, useMemo } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { doc } from "firebase/firestore";
import { CircularProgress, Stack } from "@mui/material";

import { useFirestoreBasePath } from "../../hooks/useFirestoreBasePath";
import { useCompSeasonId } from "../../hooks/useCompSeasonId";
import { ufflUserRoundConverter } from "../../models/round";
import { UfflUser } from "../../models/uffl";
import { SubstitutionsForm } from "./SubstitutionsForm";

export type SubstitutionsDataProps = {
  roundId: string;
  user: UfflUser;
};
export const SubstitutionsData: FunctionComponent<SubstitutionsDataProps> = ({
  roundId,
  user
}) => {
  const compSeasonId = useCompSeasonId();
  const firestoreBasePath = useFirestoreBasePath();

  const docRef = doc(
    useFirestore(),
    firestoreBasePath,
    "uffl",
    "compSeasons",
    compSeasonId,
    "rounds",
    roundId,
    "teams",
    user.uid
  ).withConverter(ufflUserRoundConverter);

  const { status, data } = useFirestoreDocData(docRef, {
    idField: "uid"
  });

  const firebaseLineup = useMemo(() => data?.lineup, [data]);

  if (status === "loading") return <CircularProgress />;

  if (firebaseLineup) {
    return (
      <Stack justifyContent="center">
        <SubstitutionsForm docRef={docRef} lineup={firebaseLineup} />
      </Stack>
    );
  }

  return null;
};
