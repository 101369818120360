import { FunctionComponent, useEffect, useMemo } from "react";
import _ from "lodash";

import { useAppSelector, useAppDispatch } from "../redux/hooks";

import { appConfigActions } from "../slices/appConfigSlice";
import { roundStatusActions } from "../slices/roundStatusSlice";
import { now } from "../utils/datetime";

export const Clock: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      // dispatch(appConfigActions.autoSetUtcNow());
      const utcNow = now();
      dispatch(appConfigActions.setUtcNow(utcNow));
      // dispatch(roundStatusActions.setTimeUntilStart(utcNow));
    }, 1000);
    return () => clearInterval(timer);
  }, [dispatch]);

  return null;
};

export const RoundStatusListener: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const doClock = useAppSelector((state) => state.appConfig.doClock);
  const utcNow = useAppSelector((state) => state.appConfig.utcNow);
  const rounds = useAppSelector((state) => state.aflRounds.list);

  const roundsAscending = useMemo(
    () => _.sortBy(rounds, ["utcStartTime"]),
    [rounds]
  );

  const nextRound = useMemo(
    () => _.find(roundsAscending, (round) => round.utcStartTime > utcNow),
    [roundsAscending, utcNow]
  );

  const currentRound = useMemo(
    () => _.findLast(roundsAscending, (round) => round.utcStartTime < utcNow),
    [roundsAscending, utcNow]
  );

  useEffect(() => {
    if (!nextRound) return;
    dispatch(roundStatusActions.setNextRound(nextRound));
  }, [nextRound, dispatch]);

  useEffect(() => {
    if (!currentRound) return;
    dispatch(roundStatusActions.setCurrentRound(currentRound));
  }, [currentRound, dispatch]);

  return doClock ? <Clock /> : null;
};
