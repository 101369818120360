import _ from "lodash";
import { UfflStartingPosition, Lineup, subsPositions, UfflReservePosition } from "./uffl";
import { PlayerDisplayItem, PlayerStatsFull } from "./player";
import { AflMatchScore, AflMatch, AflMatchStatus, AflMatchParticipant } from "./afl";

/*
TODO: 2023 changes
sanity
  [ ] implement rulesets in a more configurable, less hardcodey way
  [ ] unpick the quick and dirty changes i'm making right now T_T
scoring
  [x] no coach point
  [x] full forward gets 1 point per mark inside 50
*/

export enum ScoringSeason {
  CD_S2022014 = "CD_S2022014",
  CD_S2023014 = "CD_S2023014",
  CD_S2024014 = "CD_S2024014"
}

export type UfflStat =
  | "behinds"
  | "disposals"
  | "freesAgainst"
  | "goals"
  | "inside50s"
  | "kicks"
  | "marks"
  | "marksInside50" // TODO: 2023 scoring change
  | "onePercenters"
  | "tackles"
  | "tacklesInside50";

type UfflStatValue = Record<UfflStat, number>;

export const defaultWeightings: UfflStatValue = {
  behinds: 1,
  disposals: 1,
  freesAgainst: 5,
  goals: 6,
  inside50s: 3,
  kicks: 1,
  marks: 2,
  marksInside50: 1, // TODO: 2023 scoring change
  onePercenters: 2,
  tackles: 3,
  tacklesInside50: 6
};

export const positionWeightedStats: Record<UfflStartingPosition, UfflStat[]> = {
  FBK: ["marks", "onePercenters"],
  HBK: ["kicks", "marks"],
  MD1: ["disposals"],
  MD2: ["disposals"],
  SPD: ["freesAgainst"],
  HFW: ["behinds", "goals", "inside50s", "tacklesInside50"],
  FFW: ["behinds", "goals", "marks", "marksInside50"] // TODO: 2023 scoring change
};

export interface UfflStatResult {
  count: number;
  weight: number;
  value: number;
}

type UfflPositionStatsResult<T extends UfflStartingPosition> = {
  [s in typeof positionWeightedStats[T] as UfflStat]: UfflStatResult;
};

export type UfflPositionResult<T extends UfflStartingPosition> = {
  player: PlayerDisplayItem;
  stats: UfflPositionStatsResult<T>;
  total: number;
};

export type UfflLineupResult = {
  [P in UfflStartingPosition]: UfflPositionResult<P>;
};

export type AflClubResult = {
  clubId: string;
  clubName: string;
  value: number;
  score?: AflMatchScore;
};

export type UfflTeamResult = {
  teamName: string;
  total: number;
  lineup: UfflLineupResult;
  // club: AflClubResult; // TODO: 2023 scoring change
};

const getUfflStatResult = (stat: UfflStat, count = 0): UfflStatResult => {
  const weight = defaultWeightings[stat];
  const value = count * weight;
  return { count, weight, value };
};

// TODO: should this be a hook?
export const getUfflPositionStatsResult = (
  position: UfflStartingPosition,
  totals?: PlayerStatsFull
): UfflPositionStatsResult<typeof position> => {
  const weightedStats = positionWeightedStats[position];
  const result = _.fromPairs(weightedStats.map((stat) => [stat, getUfflStatResult(stat, totals?.[stat])]));
  return result as UfflPositionStatsResult<typeof position>;
};

// TODO: should this be a hook?
export const getUfflPositionResult = (
  position: UfflStartingPosition,
  lineup: Lineup,
  playerStats: PlayerStatsFull[],
  displayItems: PlayerDisplayItem[]
): UfflPositionResult<typeof position> => {
  let id: string;
  const { playerId, substituted } = lineup[position];
  id = playerId;
  if (substituted) {
    const reservePosition = _.findKey(subsPositions, (p) => p.includes(position)) as UfflReservePosition;
    id = lineup[reservePosition].playerId;
  }
  const player = _.find(displayItems, ["playerId", id]) ?? {
    playerId: id,
    displayName: "Player not found"
  };
  const totals = _.find(playerStats, ["playerId", id]);
  const stats = getUfflPositionStatsResult(position, totals);
  const total = _.sum(_.values(_.mapValues(stats, "value")));
  return { player, stats, total };
};

// TODO: should this be a hook?
export const getUfflLineupResult = (
  lineup: Lineup,
  playerStats: PlayerStatsFull[],
  displayItems: PlayerDisplayItem[]
): UfflLineupResult => {
  return _.mapValues(UfflStartingPosition, (pos) => getUfflPositionResult(pos, lineup, playerStats, displayItems));
};

// TODO: should this be a hook?
export const getAflClubResult = (clubId: string, clubName: string, matches: AflMatch[]): AflClubResult => {
  const match = _.find(
    matches,
    _.cond([
      [_.matchesProperty("home.team.club.providerId", clubId), _.constant(true)],
      [_.matchesProperty("away.team.club.providerId", clubId), _.constant(true)],
      [_.stubTrue, _.constant(false)]
    ])
  );
  const result: AflClubResult = {
    clubId,
    clubName,
    value: 0
  };
  if (!match || match.status !== AflMatchStatus.CONCLUDED) {
    return result;
  }

  const isUs = (p: AflMatchParticipant): boolean => p.team.club.providerId == clubId;

  const them: AflMatchParticipant = isUs(match.home) ? match.away : match.home;
  const us: AflMatchParticipant = isUs(match.home) ? match.home : match.away;
  result.clubName = us.team.club.name;
  if (!us.score || !them.score) {
    return result;
  }

  result.value = us.score.totalScore > them.score.totalScore ? 1 : 0;
  return result;
};

// TODO: should this be a hook?
export const getUfflTeamResult = (
  teamName: string,
  clubId: string,
  clubName: string,
  matches: AflMatch[],
  lineup: Lineup,
  playerStats: PlayerStatsFull[],
  displayItems: PlayerDisplayItem[]
): UfflTeamResult => {
  // TODO: 2023 scoring change
  // const clubResult = getAflClubResult(clubId, clubName, matches);
  const lineupResult = getUfflLineupResult(lineup, playerStats, displayItems);
  const lineupTotal = _.sum(_.values(_.mapValues(lineupResult, "total")));
  // TODO: 2023 scoring change
  // const total = clubResult.value ? clubResult.value + lineupTotal : lineupTotal;
  const result: UfflTeamResult = {
    teamName,
    total: lineupTotal,
    lineup: lineupResult
    // TODO: 2023 scoring change
    // club: clubResult
  };
  return result;
};
